
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/data/skeleton/BasicUsage.vue";
import EUIConfigurableRows from "@/views/resources/documentation/element-ui/data/skeleton/ConfigurableRows.vue";
import EUIAnimation from "@/views/resources/documentation/element-ui/data/skeleton/Animation.vue";
import EUICustomizedTemplate from "@/views/resources/documentation/element-ui/data/skeleton/CustomizedTemplate.vue";
import EUILoadingState from "@/views/resources/documentation/element-ui/data/skeleton/LoadingState.vue";
import EUIRenderingAListOfData from "@/views/resources/documentation/element-ui/data/skeleton/RenderingAListOfData.vue";
import EUIAvoidingRenderingBouncing from "@/views/resources/documentation/element-ui/data/skeleton/AvoidingRenderingBouncing.vue";

export default defineComponent({
  name: "skeleton",
  components: {
    EUIBasicUsage,
    EUIConfigurableRows,
    EUIAnimation,
    EUICustomizedTemplate,
    EUILoadingState,
    EUIRenderingAListOfData,
    EUIAvoidingRenderingBouncing
  },
  setup() {
    setCurrentPageTitle("Skeleton");
  }
});
