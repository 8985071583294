
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code7 } from "./data";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dayjs = require("dayjs");

export default defineComponent({
  name: "avoiding-rendering-bouncing",
  data() {
    return {
      loading: false,
      currentDate: dayjs().format("YYYY-MM-DD")
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code7
    };
  }
});
